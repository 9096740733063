import React, { useContext, useState } from 'react'
import { toast } from 'react-toastify'

import _ from 'lodash'
import moment from 'moment'

import apiReports from 'api/reports'
import apiServiceStock from 'api/service-stock'

import { INTERNAL_REPORTS_TYPE, FILENAMES } from 'utils/constants'
import { exportAsExcelFileMultiSheet, exportAsExcelFile } from '../utils/excel'
import { exportAsTablePdfFile } from 'utils/pdf'
import { useTranslation } from 'react-i18next'

import { UserContext } from './UserStore'
import { AppContext } from './AppStore'

const initialState = {
    isToExport: false,
    isPdf: false,
    initDate: undefined,
    endDate: undefined,
    value: undefined,
    modalLoading: false
}

export const ExportContext = React.createContext(initialState)

export const ExportProvider = ({ children }) => {

    const { idToken } = useContext(UserContext)

    const [modalLoading, setModalLoading] = useState(false)
    const [value, setValue] = useState(undefined)
    const [initDate, setInitDate] = useState(undefined)
    const [endDate, setEndDate] = useState(undefined)
    const [isPdf, setIsPdf] = useState(null)
    const [multiValue, setMultiValue] = useState([])
    const { t } = useTranslation()
    const { lengthUnit, weightUnit, convertToCurrentWeightUnitWithoutCulture, convertToCurrentLengthUnitWithoutCulture } = useContext(AppContext)

    const formatExcelExport = (data, columns) => {
        const columnsName = columns.map(item => item.name)
        let translate = []
        columnsName.forEach((column) => {
            translate.push(translate[column] = column)
        })
        const arrayToExcel = []
        data.forEach((row) => {
            const objRow = {}
            columns.forEach(column => {
                objRow[translate[column.name]] = row[column.prop]
            })
            arrayToExcel.push(objRow)
        })
        return arrayToExcel
    }

    const getKeyLabel = (key) => key.includes('length_ft') ? t(key) + '[ft]' :  key.includes('length_m') ? t(key) + '[m]' : key.includes('totalFootage') ? t(key) + ' [' + lengthUnit + ']' : key.includes('length') ? t(key) + '[' + lengthUnit + ']' : key.includes('weight') ? t(key) + '[' + weightUnit + ']' : t(key)
    const formatExcelValues = (data) => {
        const keys = Object.keys(data)
        if (keys && keys.length > 0) {
            return keys.map(key => ({ name: getKeyLabel(key), prop: key }))
        }
        return []
    }

    const formatPDFValues = (data) => {
        const keys = Object.keys(data)
        if (keys && keys.length > 0) {
            return keys.map(key => ({ accessor: key, label: getKeyLabel(key) }))
        }
        return []
    }

    const formatExcelMultiSheets = (stockData, receivingInspectionData, deliveryNotesData, rigReturnData, materialRequisitionData, batchEntranceData, rackData, pipeAgeData) => {
        let sheets = []
        if (stockData.tableData.length > 0) {
            const columnsValue = formatExcelValues(stockData.tableData[0])
            sheets.push({ name: 'Stock Level Report', data: formatExcelExport(stockData.tableData, columnsValue) })
        }
        if (rackData.length > 0) {
            const columnsValue = formatExcelValues(rackData[0])
            sheets.push({ name: 'Rack Distribution', data: formatExcelExport(rackData, columnsValue) })
        }
        if (receivingInspectionData && receivingInspectionData.length > 0) {
            const columnsValue = formatExcelValues(receivingInspectionData[0])
            sheets.push({ name: 'Receiving Inspection', data: formatExcelExport(receivingInspectionData, columnsValue) })
        }
        if (rigReturnData && rigReturnData.length > 0) {
            const columnsValue = formatExcelValues(rigReturnData[0])
            sheets.push({ name: 'Rig Return List', data: formatExcelExport(rigReturnData, columnsValue) })
        }
        if (materialRequisitionData && materialRequisitionData.length > 0) {
            const columnsValue = formatExcelValues(materialRequisitionData[0])
            sheets.push({ name: 'Material Req. Inspection List', data: formatExcelExport(materialRequisitionData, columnsValue) })
        }
        if (batchEntranceData && batchEntranceData.length > 0) {
            const columnsValue = formatExcelValues(batchEntranceData[0])
            sheets.push({ name: 'Batch Entrance List', data: formatExcelExport(batchEntranceData, columnsValue) })
        }
        if (deliveryNotesData && deliveryNotesData.length > 0) {
            const columnsValue = formatExcelValues(deliveryNotesData[0])
            sheets.push({ name: 'Delivery Notes', data: formatExcelExport(deliveryNotesData, columnsValue) })
        }
        if (pipeAgeData && pipeAgeData.length > 0) {
            const columnsValue = formatExcelValues(pipeAgeData[0])
            sheets.push({ name: 'Pipe Age List', data: formatExcelExport(pipeAgeData, columnsValue) })
        }
        return sheets
    }

    const getArrivalInspectionList = async (wid) => { //Receiving Inspection
        try {
            const response = await apiReports.getArrivalList(wid, idToken)
            const formattedData = formatReceivingInspections(response)
            return formattedData
        } catch (error) {
            throw new Error(error)
        }
    }

    const getStockData = async (wid) => {
        try {
            const data = await apiServiceStock.getServiceStockData(wid, idToken)
            const formattedData = formatStockData(data)
            formattedData.tableData.forEach(data => {
                delete data.totalFootage_ft
                delete data.totalFootage_m
            })
            return formattedData
        } catch (error) {
            throw new Error(error)
        }
    }

    const getHistoricalStockData = async (stck, stockDate) => {
        try {
            const stockDateString = stockDate.format('YYYY-MM-DD')
            const data = await apiServiceStock.getDailyServiceStock({ date: stockDateString, wid: stck.wid }, idToken)
            return data
        } catch (error) {
            throw new Error(error)
        }
    }

    const formatStockData = stockData => ({
        ...stockData,
        tableData: _.map(stockData.tableData, item => ({
            ...item,
            valids: item.valids.join(', '),
            totalFootage: (convertToCurrentLengthUnitWithoutCulture(item.totalFootage_m, item.totalFootage_ft, lengthUnit)),
            total_weight: (convertToCurrentWeightUnitWithoutCulture(Number(item.total_weight), weightUnit))
        }))
    })

    const getRackDistribution = async (wid) => {
        try {
            const data = await apiServiceStock.getRackDistribution(wid, idToken)
            const formattedData = formatRackData(data)
            return formattedData
        } catch (error) {
            throw new Error(error)
        }
    }

    const formatRackData = data => data.map(item => ({ ...item, valids: item.valids.join(', ') })) || []

    const formatReceivingInspections = receivingInspections => _.chain(receivingInspections) //Receiving Inspection
        .orderBy('inspection_date', 'desc')
        .value()

    const getDeliveryNotesData = async (wid) => { // DeliveryNotes
        try {
            const startDate = moment().subtract(1, 'months').format("DD/MM/YYYY")
            const endDate =  moment().add(1, 'day').startOf('day').format("DD/MM/YYYY")
            const params = { wid: wid, startDate: startDate, endDate }
            const response = await apiReports.getDeliveryNotesList(params, idToken)
            const formattedData = formatDeliveryNotes(response)
            return formattedData
        } catch (error) {
            throw new Error(error)
        }
    }

    const formatDeliveryNotes = (deliveryNotes) => _.chain(deliveryNotes) // Delivery Notes
        .orderBy('closure_date', 'desc')
        .value()

    const getRigReturnReportList = async (wid) => { // Rig Return
        try {
            const response = await apiReports.getRigReturnList(wid, idToken)
            const formattedData = formatRigReturnReportList(response)
            return formattedData
        } catch (error) {
            throw new Error(error)
        }
    }

    const formatRigReturnReportList = reports => _.orderBy(reports, 'closure_date', 'desc')

    const getMaterialRequisitionReportList = async (wid) => {
        try {
            const response = await apiReports.getMaterialRequisitionList(wid, idToken)
            const formattedData = formatMaterialRequisitionList(response)
            return formattedData
        } catch (error) {
            throw new Error(error)
        }
    }

    const formatMaterialRequisitionList = reports => _.chain(reports)
        .orderBy('closure_date', 'desc')
        .value()

    const getReceiptNotesReportList = async (wid) => {
        try {
            const params = { wid: wid }
            const response = await apiReports.getReceiptNotesList(params, idToken)
            const formattedData = formatBatchEntranceReportList(response)
            return formattedData
        } catch (error) {
            throw new Error(error)
        }
    }

    const formatBatchEntranceReportList = reports => _.chain(reports)
        .orderBy('entrance_date', 'desc')
        .value()

    const getPipeAgeList = async (wid) => {
        try {
            const response = await apiReports.getPipeAgeReportsList(wid, idToken)
            const formattedData = formatPipeAgeList(response.tableData)
            return formattedData
        } catch (error) {
            throw new Error(error)
        }
    }

    const formatPipeAgeList = reports => _.chain(reports)
        .orderBy('entrance_date', 'desc')
        .value()

    const downloadBackup = async (stock) => {
        try {
            const [stockData, receivingInspectionData, deliveryNotesData, rigReturnData, rigPrepData, batchEntranceData, rackData, pipeAgeData] =
            await Promise.all([ getStockData(stock?.wid), 
                                getArrivalInspectionList(stock?.wid), 
                                getDeliveryNotesData(stock?.wid), 
                                getRigReturnReportList(stock?.wid), 
                                getMaterialRequisitionReportList(stock?.wid), 
                                getReceiptNotesReportList(stock?.wid), 
                                getRackDistribution(stock?.wid), 
                                getPipeAgeList(stock?.wid)
                            ])

            const allReportsList = [stockData, receivingInspectionData, deliveryNotesData, rigReturnData, rigPrepData, batchEntranceData, rackData, pipeAgeData]
            const listsWithData = allReportsList.filter(item => item?.length > 0) || []
            if(listsWithData?.length === 0) {
                throw new Error('No Data available')
            }


            const workbook = formatExcelMultiSheets(...allReportsList)
            if (workbook && workbook.length > 0) {
                exportAsExcelFileMultiSheet(workbook, FILENAMES.INVENTORY_BACKUP + moment(new Date()).format('DD_MM_YYYY_HH_mm_ss'))
            }
        }
        catch (error) {
            console.log(error)
            toast.error(error.message)
        }
    }


    const exportToPdf = async (name, data, title = null) => {
        const header = {
            title: title ? title : name + '_' + moment(new Date()).format('DD_MM_YYYY_HH_mm_ss'),
            subTitle: ' ',
            logo: 'Smart'
        }
        const columnsConfig = formatPDFValues(data[0])
        const headers = await _.map(columnsConfig, 'label')
        const body = _.map(data, row => (
            _.reduce(columnsConfig, (acc, { accessor, formatter }) => {
                const value = _.get(row, accessor)
                return [
                    ...acc,
                    value ? (formatter ? formatter(value) : value) : '-'
                ]
            }, [])
        ))

        const reportData = [headers, ...body]
        const options = {
            pageOrientation: 'landscape',
            pageSize: 'A3',
            watermark: false,
            zebraStyle: true
        }

        exportAsTablePdfFile(header, reportData, options)
    }

    const getStockLevelInternalReportsData = async (type, stock) => {
        try {
            const response = await getHistoricalStockData(stock, initDate)
            if (response && response.length > 0) {
                const formattedData = formatStockReports(response)
                const fileName = FILENAMES.STOCK_LEVEL_INTERNAL_REPORT + '_' + moment(new Date()).format('DD_MM_YYYY_HH_mm_ss') + '_FROM_' + moment(initDate).format('DD_MM_YYYY')
                if (isPdf) {    
                    const newFormattedData = formattedData.map(item => {
                        return _.omit(item, ['total_length_ft', 'total_length_m'])
                    })       
                    await exportToPdf(type, newFormattedData, fileName)
                }
                else{
                    const newFormattedData = formattedData.map(item => {
                        return _.omit(item, ['total_length_ft', 'total_length_m'])
                    })
                    exportAsExcelFile(formatExcelExport(newFormattedData, formatExcelValues(formattedData[0])), fileName)
                }
            }
            else {
                toast.warn(t('There isn\'t data for the selected period'))
            }

        } catch (error) {
          toast.warn(t('There isn\'t data for the selected period'))
        }
    }

    const getConsumptionInternalReportsData = async (wid, name) => {
        try {
            const response = await apiReports.getDeliveryNotesByPeriod({ 
                wid: wid,
                startDate: moment(new Date(initDate)).format('DD/MM/YYYY'), 
                endDate: moment(new Date(endDate)).format('DD/MM/YYYY'),
                name: name
            }, idToken)
            
            if (response && response.length > 0) {
                const formattedData = await formatInternalReports(response)
                if (isPdf){
                    const newFormattedData = formattedData.map(item => {
                        return _.omit(item, ['total_length_ft', 'total_length_m','parts'])
                    })   
                    await exportToPdf('DN_' + multiValue, newFormattedData)
                }
                else{
                    const newFormattedData = formattedData.map(item => {
                        return _.omit(item, ['total_length_ft', 'total_length_m','parts'])
                    })   
                    exportAsExcelFile(formatExcelExport(newFormattedData, formatExcelValues(newFormattedData[0])),'DN_' + moment(new Date()).format('DD_MM_YYYY_HH_mm_ss'))
                }
            }
            else {
                toast.warn(t('There isn\'t data for the selected period'))
            }

        } catch (error) {
            throw new Error(error)
        }
    }

    const getReceiptInternalReportsData = async (type) => {
        try {
            const response = await apiReports.getReceiptInternalReports({ initDate: moment(new Date(initDate)).format('YYYY-MM-DD'), 
                                                                          endDate: moment(new Date(endDate)).format('YYYY-MM-DD') }, idToken)
            if (response && response.length > 0) {
                const formattedData = formatInternalReports(response)
                if (isPdf){
                    const newFormattedData = formattedData.map(item => {
                        return _.omit(item, ['total_length_ft', 'total_length_m','parts'])
                    })   
                  
                    await exportToPdf(type, newFormattedData)
                }
                else{
                    const newFormattedData = formattedData.map(item => {
                        return _.omit(item, ['total_length_ft', 'total_length_m','parts'])
                    })   
                    exportAsExcelFile(formatExcelExport(newFormattedData, formatExcelValues(newFormattedData[0])), FILENAMES.RECEIPT_NOTES + '_' + moment(new Date()).format('DD_MM_YYYY_HH_mm_ss'))
                }
            }
            else {
                toast.warn(t('There isn\'t data for the selected period'))
            }
        } catch (error) {
            throw new Error(error)
        }
    }

    const formatStockReports = reports => _.chain(reports)
        .map((item) => ({
          sapReference: item.sapReference,
          productType: item.productType,
          nominalOD: item.nominalOd,
          nominalWeight: item.nominalWeight,
          grade: item.grade,
          connection: item.connection,
          couplingOption: item.couplingOption,
          drift: item.drift,
          nominalLength: item.nominalLength,
          modifiedProductDesc: item.modifiedProductDesc,
          qtdPC: item.qtdPc ? Math.round(item.qtdPc, 0) : 
                 item.qtdPC ? Math.round(item.qtdPC, 0) : '',
          dscStatusMaterial: item.dscStatusMaterial,
          rackLocation: item.rackLocation,
          totalFootage: (convertToCurrentLengthUnitWithoutCulture(item.totalFootage_m, item.totalFootage_ft, lengthUnit)),
          total_weight: item.totalWeight ? (convertToCurrentWeightUnitWithoutCulture(Number(item.totalWeight), weightUnit)) : item.total_weight ? (convertToCurrentWeightUnitWithoutCulture(Number(item.total_weight), weightUnit)) : '',
          endUser: item.endUser,
          owner_name: item.ownerName,
          dscMaterial: item.dscMaterial,
          makeupLoss: item.makeupLoss,
          nominalWT: item.nominalWt,
          nominalODExt: item.nominalOdExt,
          nominalWeightExt: item.nominalWeightExt,
          gradeExt: item.gradeExt,
          connectionExt: item.connectionExt,
          couplingOptionExt: item.couplingOptionExt,
          makeupLossExt: item.makeupLossExt,
          nominalWTExt: item.nominalWtExt,
          standard: item.standard,
          revision: item.revision,
        }))
        .orderBy('entrance_date', 'desc')
        .value()

    const formatInternalReports = reports => _.chain(reports)
        .map((item) => ({
            ...item,
            pipe_count: Math.round(item.pipe_count ?? item.total_pipe_count, 0),
            total_weight: convertToCurrentWeightUnitWithoutCulture(Number(item.total_weight), 'kg'),
            total_length: convertToCurrentLengthUnitWithoutCulture(item.total_length_m, item.total_length_ft, lengthUnit),
            closure_date: new Date(item.closure_date)
        }))
        .orderBy('closure_date', 'desc')
        .value()

    const exportInternalReports = async (type, stock, name) => {
        try {
            type === INTERNAL_REPORTS_TYPE.STOCK_LEVEL ? await getStockLevelInternalReportsData(type, stock) :
            type === INTERNAL_REPORTS_TYPE.CONSUMPTION ? await getConsumptionInternalReportsData(stock?.wid, name) :
            await getReceiptInternalReportsData(type)
        }
        catch (error) {
            console.log(error)
            toast.error(error.message)
        }
    }

    const renderStore =
        <ExportContext.Provider value={{
            downloadBackup,
            exportInternalReports,
            modalLoading, setModalLoading,
            value, setValue,
            initDate, setInitDate,
            endDate, setEndDate,
            isPdf, setIsPdf,
            multiValue, setMultiValue
        }}>
            {children}
        </ExportContext.Provider>

    return renderStore
}
